@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer {
	background: #342f29;
	
	&__inner {
		max-width: 1050px;
		margin: 0 auto 40px;
		padding: 0 25px;
	}
	&__nav {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 110px;
		margin: 0 0 35px;
		border-bottom: 1px solid #433e37;
		
		&__item {
			margin: 0 15px;
			color: $white;
			
			&:hover {
				color: $white;
				text-decoration: underline;
			}
		}
	}
	&__address {
		color: $white;
		font-size: $xxs;
		text-align: center;
		
		&__logo {
			width: 170px;
			margin: 0 auto 15px;
		}
	}
	.copyright {
		padding: 25px 0;
		background: #000;
		color: #8d8275;
		font-size: $xxs;
		font-weight: bold;
		text-align: center;
	}
}