@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
.footer {
	background: #342f29;
	
	&__inner {
		margin: 0 0 20px;
	}
	&__nav {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 20px;
		background: #6f5f4f;
		
		&__item {
			width: 50%;
			padding: 10px 15px;
			border-bottom: 1px solid rgba($white, 0.3);
			color: $white;
			font-size: $xxs;
			
			&:nth-child(2n+1) {
				border-right: 1px solid rgba($white, 0.3);
			}
			&:last-child {
				border-bottom: none;
			}
			.icon-angle-right {
				margin: 0 5px 0 0;
				font-size: $xxxs;
			}
		}
	}
	&__address {
		color: $white;
		font-size: $xxxs;
		text-align: center;
		
		&__logo {
			width: 150px;
			margin: 0 auto 10px;
		}
	}
	.copyright {
		padding: 15px 0;
		background: #000;
		color: #8d8275;
		font-size: $xxxs;
		font-weight: bold;
		text-align: center;
	}
}