@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
.header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 200;
	width: 100%;
	background: none !important;
	
	a:hover {
		@include opacity;
	}
	
	.header-content1 {
		height: 35px;
		background: #342f29;
		
		&__inner {
			display: flex;
			align-items: center;
			max-width: 1050px;
			margin: 0 auto;
			padding: 0 25px;
		}
		&__tagline {
			flex: 1 1 auto;
			margin: 0 20px 0 0;
			color: $white;
			font-size: 1.1rem;
			line-height: 1.3;
		}
	}
	
	.header-content2 {
		display: flex;
		align-items: center;
		max-width: 1050px;
		margin: 0 auto;
		padding: 0 25px;
		height: 85px;
		
		@media screen and (max-width: 900px) {
			height: 75px;
		}
		
		&__logo {
			flex: 1 1 auto;
			
			& > * {
				display: block;
				width: 210px;
			}
		}
		&__nav {
			display: flex;
			margin: 10px 0 0;
			
			&__link {
				margin: 0 0 0 20px;
				color: $white;
				font-size: $xxs;
				
				@media screen and (max-width: 800px) {
					margin: 0 0 0 15px;
				}
				
				.icon-angle-right {
					margin: 0 4px 0 0;
					color: $beige;
				}
			}
		}
		&__tel {
			display: flex;
			align-items: flex-end;
			margin: 0 0 0 25px;
			color: $white;
			line-height: 1;
			
			@media screen and (max-width: 800px) {
				margin: 0 0 0 15px;
			}
			
			.tel-link {
				font-size: $xl;
				line-height: 0.7;
				.icon-phone {
					margin: 0 5px 0 0;
					color: $beige;
				}
			}
			&__text {
				margin: 0 0 0 10px;
				font-size: $xxs;
			}
		}
	}
	.global-nav {
		display: block !important;
		max-width: 1050px;
		margin: 0 auto;
		padding: 0 25px;
		
		.menu {
			display: flex;
		}
		.menu-item {
			flex: 1 1 auto;
			display: flex;
			border: none !important;
			border-left: 1px solid rgba($white, 0.2) !important;
			text-align: center;
			
			&:last-child {
				border-right: 1px solid rgba($white, 0.2) !important;
			}
			
			a  {
				width: 100%;
				padding: 0 !important;
				background: none !important;
				color: $white;
				font-size: 1.5rem;
				line-height: 25px;
			}
		}
	}
}


