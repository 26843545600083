@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
body {
	background: #f7f0e5;
}

.wrap {
	position: relative;
	color: $textColor;
	font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", YuMincho, "HG明朝E", "ＭＳ Ｐ明朝", serif;
	font-size: $s;
	line-height: 1.8;
}

.pc-view {
	display: none !important;
}

a {
	color: $linkColor;
	text-decoration: underline;

	.header &,
	.footer & {
		text-decoration: none;
	}
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 25px;
	padding: 10px 0;
	border-bottom: 1px solid $borderColor;
	font-size: $xxxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		padding: 0 15px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 15px;
	bottom: 15px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: rgba($baseColor, 0.9);
		color: $white;
		font-size: $xxxl;
		@include radius(4);
	}
}