@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
.header {
	background: #342f29;
	
	.header-content2 {
		display: flex;
		align-items: center;
		
		&__logo {
			flex: 1 1 auto;
			margin: 0 0 0 15px;
			a {
				display: block;
				width: 147px;
				font-size: 0;
			}
		}
		&__tel {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45px;
			height: 45px;
			background: #ddc395;
			color: #342f29;
			font-size: $xxxl;
		}
		&__menu {
			position: relative;
			z-index: 150;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45px;
			height: 45px;
			background: #ddc395;
			border-left: 1px solid rgba(#342f29, 0.3);
			color: #342f29;
			font-size: $xxl;
			@include transition;
			
			.gnav-show & {
				position: fixed;
				top: 0;
				right: 0;
				background: none;
				color: $white;
			}
			
			.icon-bars {
				
				.gnav-show &::before {
					content: "\e5cd";
				}
			}
		}
	}
	.global-nav {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		display: block !important;
		width: 100vw;
		height: 100vh;
		padding: 50px 0 0;
		background: rgba(#000, 0.9);
		opacity: 0;
		visibility: hidden;
		@include transition;
		
		ul {
			display: flex;
			flex-wrap: wrap;
			border-top: 1px solid rgba($white, 0.2) !important;
		}
		
		.gnav-show & {
			opacity: 1;
			visibility: visible;
		}
		
		.menu-item {
			width: 50%;
			float: none !important;
			border: none !important;
			border-bottom: 1px solid rgba($white, 0.2) !important;
			
			&:nth-child(2n+1) {
				border-right: 1px solid rgba($white, 0.2) !important;
			}
		
			a {
				position: relative;
				display: block;
				padding: 20px 5px 20px 30px !important;
				background: none !important;
				color: $white;
				font-size: $m;
				text-align: left !important;
				line-height: 1.4;
				
				&::before {
					left: 15px;
					content: "\f105";
					font-family: 'icomoon';
					font-size: $xxxs;
					@include centering-elements(false, true);
				}
			}
		}
		
		.sub-nav {
			margin: 0 15px;
			
			&__item {
				display: flex;
				align-items: center;
				margin: 0 0 10px;
				padding: 0;
				font-size: $s;
			}
			.icon-angle-right {
				margin: 0 8px 0 0;
				font-size: $xxxs;
			}
		}
	
	
	}
}