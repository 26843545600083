@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
.home {
	
	#contents {
		padding-bottom: 0;
	}
	#main {
		max-width: none;
		padding: 0;
	}
	#miryoku {
		padding: 55px 0;
	}
	
	.row {
		max-width: 1050px;
		margin: 0 auto;
		padding: 0 25px;
	}
	
	.ttl-block {
		position: relative;
		
		.btn {
			right: 0;
			@include centering-elements(false, true);
		}
	}
	
	
	.metaslider {
		width: 100%;
		overflow: hidden;
	}
	
	
	/* index-content1
	----------------------------------------------------------- */
	.index-content1 {
		display: flex;
		flex-direction: row-reverse;
		background: $white;
		
		& > * {
			width: 50%;
		}
		&__img {
			position: relative;
			overflow: hidden;
			
			img {
				width: auto;
				height: auto;
				min-width: 100%;
				min-height: 100%;
				max-width: none;
				@include centering-elements(true, true);
			}
			&::after {
				display: block;
				padding-bottom: 50%;
				content: '';
			}
		}
		&__body {
			display: flex;
			justify-content: flex-end;
			
			&__inner {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				max-width: 500px;
				width: 100%;
				padding: 40px 60px;
				background: url(/wp-aiju/wp-content/themes/habakiri-child/images/bg_index_content1_02.png) left bottom no-repeat;
				
				& > * {
					max-width: 100%;
				}
			}
			.btn {
				min-width: 180px;
				height: 40px;
			}
		}
		&__ttl {
			position: relative;
			margin: 0 0 25px;
			padding: 0 0 15px;
			font-size: $xl;
			font-weight: bold;
			text-align: center;
			line-height: 1.5;

			&::after {
				bottom: -1px;
				content: '';
				width: 40px;
				border-bottom: 1px solid $textColor;
				@include centering-elements(true, false);
			}
		}
		
		&.bg-brown {
			flex-direction: row;
			background: #342f29;
			color: $white;
			
			.index-content1__body {
				justify-content: flex-start;
			}
			.index-content1__body__inner {
				background: url(/wp-aiju/wp-content/themes/habakiri-child/images/bg_index_content1_01.png) right bottom no-repeat;
			}
			.index-content1__ttl::after {
				border-bottom-color: $white;
			}
		}
	}
	
	
	/* index-content2
	----------------------------------------------------------- */
	.index-content2 {
		max-width: 1050px;
		margin: 45px auto;
		padding: 0 25px;
		
		&__ttl {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 0 25px;
			color: #706657;
			font-size: $xl;
			line-height: 1.5;
			
			.icon-bookmark {
				margin: 0 0 15px;
				color: $red;
				font-size: $xxxl;
			}
			&__text {
				display: flex;
				align-items: center;
				&::before,
				&::after {
					content: '';
					width: 60px;
					margin: 0 25px;
					border-bottom: 1px solid #dad1c3;
				}
			}
		}
		&__benefits {
			display: flex;
			max-width: 900px;
			margin: 0 auto;
			padding: 20px 0;
			border-top: 1px solid #dad1c3;
			border-bottom: 1px solid #dad1c3;
			
			&__ttl {
				flex: 0 0 auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 180px;
				margin: 0;
				padding: 0 25px 0 0;
				border-right: 3px solid #dad1c3;
				color: $red;
				font-size: $xxxl;
				text-align: center;
				line-height: 1.5;
			}
			&__body {
				flex: 1 1 auto;
				display: flex;
				margin: 0;
			}
			&__item {
				width: 33.3%;
				border-left: 1px solid #dad1c3;
				text-align: center;
				line-height: 1.6;
				
				&__number {
					margin: -0.2em 0 20px;
					color: $red;
					font-family: 'Cormorant', serif;
					font-size: 4.0rem;
					line-height: 0.8;
				}
			}
		}
	}
	
	
	/* search
	----------------------------------------------------------- */
	#search {
		max-width: 1050px;
		margin: 0 auto 60px;
		padding: 0 25px;
		padding-bottom: 15px;
		
		.search_pad {
			float: none;
			width: 100%;
			padding: 0;

			.midashi_h2 {
				margin: 0 0 25px;
			}
			form {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				
				& > * {
					margin: 0 0 10px;
				}

				span {
					margin: 0 20px 0 10px;
					padding: 0;
				}
			}
			select {
				height: 45px;
				padding: 0 10px;
				font-size: $m;
				cursor: pointer;
			}
		}
		
		.search__tel {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 20px 0 0;
			padding: 15px 0 0;
			border-top: 1px solid #ebe6dd;
			line-height: 1.5;
			
			.icon-phone,
			.tel-link {
				font-size: 2.6rem;
			}
		}
	}
	
	
	/* osusume
	----------------------------------------------------------- */
	#osusume {
		position: relative;
		margin: 0 0 70px;
		padding-top: 75px;
		
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			z-index: -1;
			display: block;
			width: 100%;
			height: 325px;
			background: $white;
		}
	}
	
	
	/* room
	----------------------------------------------------------- */
	#room {
		padding: 75px 0 0;
		background: $white;
	}

	
	/* access
	----------------------------------------------------------- */
	#access {
		.service {
			display: flex;
			
			&__item {
				display: flex;
				align-items: center;
				width: calc(100% / 3);
				padding: 15px 25px;
				border-right: 1px solid #e0d8cb;
				color: #5c5145;
				
				&:first-child {
					border-left: 1px solid #e0d8cb;
				}
				
				&__icon {
					flex: 0 0 auto;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 20px 0 0;
					background: #6f5f4f;
					color: $white;
					font-size: 2.6rem;
					@include circle(55);
				}
				&__inner {
					flex: 1 1 auto;
				}
				&__ttl {
					margin: 0;
					font-size: $l;
				}
			}
		}	
	}
	
	
}