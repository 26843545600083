@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.breadcrumbs {
	margin: 0 0 15px;
	border-bottom: 1px solid #e1dad0;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 5px 15px;
		color: #5d4d3e;
		font-size: $xxxs;
		
		& > * {
			margin: 0 8px;
			color: #5d4d3e;
			
			&:first-child {
				margin-left: 0;
			}
		}
	}
}

#main {
	margin: 0 15px;
}

.access-tel {
	font-size: $xxxl;
}

.panel-grid.panel-no-style,
.panel-grid.panel-has-style > .panel-row-style {
	& > * {
		flex: 0 0 auto;
	}
}

.article--404 {
	margin-bottom: 50px;
}

.visual {
	position: relative;
	
	&__text {
		margin: 0;
		color: $white;
		font-size: $xl;
		text-align: center;
		white-space: nowrap;
		line-height: 1.4;
		@include centering-elements(true, true);
	}
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 20px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.midashi_h2 {
	color: #5d4d3e;
	font-size: $xl;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
	
	&::before {
		display: block;
		content: attr(data-text);
		color: #b7997d;
		font-size: $s;
		font-family: 'Cormorant', serif;
		font-weight: normal;
	}
}

.faq-answer + .midashi_h2 {
	margin-top: 40px;
}

.midashi_h3 {
	position: relative;
	padding: 0 0 15px;
	color: #7e5b23;
	font-size: $l;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;
	
	&::after {
		bottom: -1px;
		content: '';
		width: 40px;
		border-bottom: 1px solid #c0a272;
		@include centering-elements(true, false);
	}
}

.midashi_h4 {
	position: relative;
	margin: 0 0 15px;
	padding: 0 0 8px;
	color: #000;
	font-size: $m;
	font-weight: bold;
	text-align: center;
	line-height: 1.4;
	
	&::after {
		bottom: -1px;
		content: '';
		width: 40px;
		border-bottom: 1px solid #aba096;
		@include centering-elements(true, false);
	}
}

.ttl05 {
	margin: 0 0 15px;
	padding: 2px 0 2px 10px;
	border-left: 3px solid $baseColor;
	font-size: $m;
	font-weight: bold;
	line-height: 1.4;
}

.ttl06 {
	margin: 0 0 15px;
	color: $baseColor;
	font-size: $s;
	font-weight: bold;
	line-height: 1.4;
}

.col_title {
	padding: 10px 15px;
	background: #e6ded1;
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 1.5;
}



/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.column3,
.column6 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

.sp-column1 {
	margin-left: 0;
	margin-right: 0;

	&> * {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.sp-column2 {
	margin-left: -1.5%;
	margin-right: -1.5%;

	&> * {
		margin-left: 1.5%;
		margin-right: 1.5%;
		width: 47%;
	}
}

.sp-column3 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 31%;
	}
}



/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&> * {
		flex: 0 0 auto;
		width: 100%;
		
		&:not(:last-child) {
			margin-bottom: 20px;
		}
		
		img {
			display: block;
			margin: 0 auto;
		}

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}


	&--right {
		.float__item {
			max-width: 100%;
			width: 100%;
			text-align: center;
			margin: 0 0 10px;

			* {
				max-width: 100%;
			}
		}
	}
}


.sp-float.float {

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			text-align: center;
			max-width: 50%;

			* {
				max-width: 100%;
			}
		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 5px;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}

	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--orange {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 15px;

	&--gray {
		background: #f9f9f9;
	}
	&--border-brown {
		border: 1px solid #dad1c3;
	}
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* panel */
/* --------------------------------------------------- */
.panel {
	border: none;
	box-shadow: 0 0 10px 5px rgba(#000, 0.03);
	@include radius(0);
	
	&__body {
		padding: 20px 15px;
	}
}


/* --------------------------------------------------- */
/* iframe */
/* --------------------------------------------------- */
.iframe {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table,
.tablepress {
	width: 100%;
	border: 1px solid #c7beb0;
	table-layout: fixed;
	font-size: $xs;
	line-height: 1.6;
	word-break: break-all;
	word-wrap: break-word;

	th,
	td {
		padding: 10px;
		background: #fff;
		border: 1px solid #c7beb0;
		text-align: left;
		vertical-align: middle;
	}
	.bg-black {
		background: #444;
		color: $white;
	}
	.bg-gray {
		background: #f3f3f3;
	}
	.bg-brown {
		background: #eee6d8;
	}
	
	
	&--sp-column1 {
		display: block;
		
		thead,
		tbody {
			display: block;
		}
		tr {
			display: block;
			&:first-child th {
				border-top: none;
			}
		}
		th,
		td {
			display: block;
			padding: 15px;
			border-left: none;
			border-right: none;
			border-bottom: none;
		}
	}
	
	&--wide {
		border: none;
		display: block;

		th,
		td {
			display: block;
			padding: 5px 8px;
			border-bottom-width: 0;
			border-right-width: 0;
			text-align: center;
		}
		th{  
			width: auto;
			text-align: left;
		}
		thead {
			display: block;
			float: left;
			overflow-x: scroll;
		}
		tbody {
			display: block;
			width: auto;
			overflow-x: auto;
			white-space: nowrap;

			tr { 
				display: inline-block; 
				margin: 0 -3px;

				&:last-child {
					th,
					td {
						border-right-width: 1px;
					}
				}
			}
		}
		thead th:last-child,
		tbody td:last-child {
			border-bottom-width: 1px;
		}
	}
	
	&--list {
		border: none;

		tr {
			display:block;
			margin: 0 0 20px;
		}
		th {
			display: block;
		}
		td {
			display: list-item;
			margin: 0 0 0 20px;
			padding: 5px 0 0;
			border: none;
			background: none;
		}
	}
	
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	min-height: 45px;
	margin: 5px 0;
	padding: 0 30px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	line-height: 1.4;
	@include radius(0);
	
	.icon-fix-left,
	.icon-fix-right {
		font-size: $xxxs;
		@include centering-elements(false, true);
	}
	.icon-fix-left {
		left: 10px;
	}
	.icon-fix-right {
		right: 10px;
	}
	.icon-arrow-right {
		font-size: 1.5rem;
	}
	
	&--gray {
		background: #656058;
		border: 1px solid #656058;
	}
	&--border-gray {
		border: 1px solid #656058;
		color: $textColor;
	}
	&--border-white {
		border: 1px solid #656058;
		color: #9e9689;
		font-weight: bold;
	}
	&--border-red {
		border: 1px solid #e0bfbf;
		color: $red;
		font-weight: bold;
	}
	&--red {
		background: $red !important;
		border: 1px solid $red !important;
	}
	&--lg {
		width: 100%;
		height: 50px;
	}
	&--sm {
		display: inline-flex;
		min-width: 0;
		min-height: 35px;
		padding: 0 20px;
		font-size: $xxs;
	}
}

input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}

button[class^="btn"] {
	display: -webkit-inline-box;
	display: -moz-inline-box;
}


/* --------------------------------------------------- */
/* pagination */
/* --------------------------------------------------- */
.pagination {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	& > li {
		margin: 0 3px 10px;

		& > * {
			display: block;
			padding: 5px 10px !important;
			background: $baseColor !important;
			border: 1px solid $baseColor !important;
			color: $white !important;
			@include radius(3);
			
			&.current {
				background: none !important;
				border-color: #e0bfbf !important;
				color: $baseColor !important;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion,
.sp-accordion {
	position: relative;
	margin: 15px 0 0;
	padding: 10px 30px 10px 15px;
	background: #e4dbcc;
	border: 1px solid #dad1c3;
	cursor: pointer;
	line-height: 1.6;
	
	&__icon {
		right: 10px;
		font-size: $m;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px;
	background: $white;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 0;
	padding: 2px 10px;
	background: $baseColor;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"],
textarea,
select {
	color: $textColor;
	font-size: 16px;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $baseColor;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $baseColor;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 45px;
	padding: 2px 0;
	background: $baseColor;
	color: #fff;
	font-size: $xxxs;
	text-align: center;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0 0 40px;
	border-top: 1px solid $borderColor;
	
	&__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 15px 0;
		border-bottom: 1px solid $borderColor;
		
		&__date {
			margin: 0 10px 0 0;
			font-size: $xxs;
		}
		&__link {
			width: 100%;
			margin: 5px 0 0;
			
			.icon-filter_none {
				color: $baseColor;
			}
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry__title {
	position: relative;
	margin: 0 0 15px;
	padding: 0 0 10px;
	color: #7e5b23;
	font-size: $l;
	font-weight: bold;
	text-align: center;
	line-height: 1.5;

	&::after {
		bottom: -1px;
		content: '';
		width: 40px;
		border-bottom: 1px solid #c0a272;
		@include centering-elements(true, false);
	}
}

.entry-meta {
	margin: 0 0 25px;
	padding: 0;
	border-bottom: none;
	font-size: $xxs;
	text-align: center;
}


/* --------------------------------------------------- */
/* page_link */
/* --------------------------------------------------- */
.page_link {
	display: flex;
	li {
		flex: 1 1 auto;
		display: flex;
		width: auto !important;
		padding: 0 !important;
		border: none !important;
		
		a {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 50px;
			padding: 0 0 5px;
			background: #e7ddcf;
			border: 1px solid #bdb2a0;
			border-left-width: 0;
			color: $textColor;
			text-decoration: none;
			.icon-angle-down {
				bottom: 0;
				@include centering-elements(true, false);
			}
			&:hover {
				@include opacity;
			}
		}
		&:first-child {
			a {
				border-left-width: 1px;
			}
		}
	}
}


/* --------------------------------------------------- */
/* other */
/* --------------------------------------------------- */
.panel-grid {
	margin-bottom: 40px;
}

.so-panel {
	margin: 0 0 15px;
}


/* ============================================================ */
/* 館内施設 */
/* /wp-aiju/inhouse-facility/ */
/* ============================================================ */
#pl-12 {
	.so-panel {
		margin-bottom: 15px !important;
	}	
	#panel-12-2-0-0,
	#panel-12-2-1-0,
	#panel-12-4-0-0,
	#panel-12-4-1-0,
	#panel-12-6-1-0 {
		margin-bottom: 10px;
	}
	#pg-12-1,
	#pg-12-3,
	#pg-12-5,
	#pg-12-11 {
		margin-bottom: 0;
	}
	#pg-12-7,
	#pg-12-8,
	#pg-12-9,
	#pg-12-10,
	#pg-12-13,
	#pg-12-14,
	#pg-12-17,
	#pg-12-18,
	#pg-12-19 {
		margin-bottom: 30px;
	}
	
}


/* ============================================================ */
/* お知らせ
/* /wp-aiju/
/* /wp-aiju/archives/category/news
/* ============================================================ */
	#news {
		.info-area {
			margin: 0;
			
			li {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding: 15px 0;
				border-bottom: 1px solid #d1c4b7;
				line-height: 1.5;
				
				.date {
					width: 100%;
					margin: 0 0 5px;
					font-size: $xxs;
				}
				.news_title {
					flex: 1 1 auto;
					padding: 0;
				}
				.icon-angle-right {
					flex: 0 0 auto;
					margin: 0 0 0 15px;
				}
			}
		}
	}
