@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
.home {
	
	#main {
		max-width: none;
		margin: 0;
		padding: 0;
	}
	#miryoku {
		padding: 30px 15px;
	}
	
	.row {
		margin: 0 15px;
	}
	
	
	/* index-content1
	----------------------------------------------------------- */
	.index-content1 {
		background: $white;
		
		&__body {
			&__inner {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 25px 15px 30px;
				background: url(/wp-aiju/wp-content/themes/habakiri-child/images/bg_index_content1_02.png) left bottom no-repeat;
			}
		}
		&__ttl {
			position: relative;
			margin: 0 0 25px;
			padding: 0 0 15px;
			font-size: $l;
			font-weight: bold;
			text-align: center;
			line-height: 1.5;

			&::after {
				bottom: -1px;
				content: '';
				width: 40px;
				border-bottom: 1px solid $textColor;
				@include centering-elements(true, false);
			}
		}
		
		&.bg-brown {
			background: #342f29;
			color: $white;
			
			.index-content1__body__inner {
				background: url(/wp-aiju/wp-content/themes/habakiri-child/images/bg_index_content1_01.png) right bottom no-repeat;
			}
			.index-content1__ttl::after {
				border-bottom-color: $white;
			}
		}
	}
	
	
	/* index-content2
	----------------------------------------------------------- */
	.index-content2 {
		margin: 40px 15px 45px;
		
		&__ttl {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 0 25px;
			color: #706657;
			font-size: $l;
			line-height: 1.5;
			
			.icon-bookmark {
				margin: 0 0 15px;
				color: $red;
				font-size: $xxxl;
			}
		}
		&__benefits {
			
			&__ttl {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0;
				padding: 15px 0;
				border-top: 1px solid #dad1c3;
				border-bottom: 3px solid #dad1c3;
				color: $red;
				font-size: $xxxl;
				text-align: center;
				line-height: 1.5;
			}
			&__item {
				display: flex;
				align-items: center;
				padding: 15px 0;
				border-bottom: 1px solid #dad1c3;
				line-height: 1.6;
				
				&__number {
					flex: 0 0 auto;
					margin: -0.2em 25px 0 10px;
					color: $red;
					font-family: 'Cormorant', serif;
					font-size: 4.0rem;
					line-height: 0.8;
				}
			}
		}
	}


	/* search
	----------------------------------------------------------- */
	#search {
		margin: 0 15px 40px;
		
		.search_pad {
			float: none;
			width: 100%;
			padding: 0;

			.midashi_h2 {
				margin: 0 0 25px;
			}
			form {

				span {
					margin: 0 5px;
					padding: 0;
				}
			}
			select {
				height: 40px;
				padding: 0 2px;
				font-size: $m;
			}
		}
		
		.search__tel {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			margin: 20px 0 0;
			padding: 15px 0 0;
			border-top: 1px solid #ebe6dd;
			text-align: center;
			line-height: 1.5;
			
			.icon-phone,
			.tel-link {
				font-size: $xxl;
			}
		}
	}
	

	/* osusume
	----------------------------------------------------------- */
	#osusume {
		position: relative;
		margin: 0 0 30px;
		padding-top: 40px;
		
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			z-index: -1;
			display: block;
			width: 100%;
			height: 200px;
			background: $white;
		}
	}

	
	/* room
	----------------------------------------------------------- */
	#room {
		padding: 40px 0 0;
		background: $white;
	}
	

	/* access
	----------------------------------------------------------- */
	#access {
		.service {
			
			&__item {
				display: flex;
				align-items: center;
				padding: 15px 10px;
				border-bottom: 1px solid #e0d8cb;
				color: #5c5145;
				
				&:first-child {
					border-top: 1px solid #e0d8cb;
				}
				
				&__icon {
					flex: 0 0 auto;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 20px 0 0;
					background: #6f5f4f;
					color: $white;
					font-size: $l;
					@include circle(40);
				}
				&__inner {
					flex: 1 1 auto;
				}
				&__ttl {
					margin: 0;
					font-size: $m;
				}
			}
		}	
	}
	
}